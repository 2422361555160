import React, { useState } from "react"
import styled from "styled-components"


const ContactForm = () => {
  const [status, setStatus] = useState(null);

  const handleSubmit = (e) => {
    e.persist()
    e.preventDefault();

    fetch("https://getform.io/f/33c6dcd5-9413-4d95-98da-c66e2d706c2b", {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        email: e.target.email.value,
        message: e.target.message.value
      })
    })
      .then(res => {
        if (res.ok) {
          setStatus("SUCCESS")
          e.target.reset()
        }
        else {
          setStatus("ERROR")
        }
      })
  }

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <div>
        <label htmlFor="email">Your email</label>
        <input type="email" name="email" id="email-label" required />
      </div>
      <div>
        <label htmlFor="message">Your message</label>
        <textarea name="message" id="message" required maxLength="3000" minLength="2"></textarea>
      </div>

      {status === "SUCCESS" ? <Feedback>Sent.</Feedback> : <button type="submit">Submit</button>}
      {status === "ERROR" && <Feedback>There was an error. Please try again later.</Feedback>}
    </Form>
  )

}

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;

  * {
    font-family: 'Inter';
  }

  & > div {
    margin-bottom: 10px;
  }

  & label {
    display: block;
    margin-bottom: 5px;
  }

  & input {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    border: none;
    background-color: #efefef;
    box-shadow: #000000 3px 3px 50px;
  }

  & textarea {
    resize: none;
    width: 100%;
    min-height: 200px;
    padding: 0;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 3px;
    border: none;
    background-color: #efefef;
    box-shadow: #000000 3px 3px 50px;
  }

  & button {
    width: 30%;
    padding: 10px;
    margin: 0 auto;
    margin-top: 15px;
    border: none;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.color.red};
    transition: background-color 0.5s, color 0.5s;
    cursor: pointer;
    color: #fff;
  }

  & button:hover {
    background-color: ${({ theme }) => theme.color.red};
    background-color: #efefef;
    color: #000;
  }
`

export const Feedback = styled.p`
  text-align: center;
`

export default ContactForm



