import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import ContactForm from "../components/contactForm"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <StyledContactPage>
      <ContactForm />
    </StyledContactPage>
  </Layout>
)

const StyledContactPage = styled.div`
  width: 100%;
`

export default Contact
